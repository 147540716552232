import React from 'react';
import { RTCPeerState } from '../../models/RTCPeerState';
import { UserMediaFeedback, UserSocketFeedback } from '../../models/Feedback';
import CenterLayout from '../../components/CenterLayout';
import StreamActions from '../../components/VideoStream/StreamActions';
import StreamPeerController from '../../components/VideoStream/StreamPeerController';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import { Message } from '../../models/Message';
import TopPanel from '../../components/TopPanel';
import StreamScreen, { VideoScreenStyleProps } from '../../components/VideoStream/StreamScreen';
import { theme_vars } from '../../constants/theme';
import { RTCPeer } from '../../models/RTCPeer';
import { useWindowSize } from '@react-hook/window-size';
import { setSizeFromScreenDimConstraint } from '../../lib/video';

interface CallInPageProps {
    localId?: string;
    selectedId?: string;
    localDeviceId?: string;
    autoSelectedId?: string;
    localAudioEnabled: boolean;
    localVideoEnabled: boolean;
    actionsActive: boolean;
    localStream: MediaStream;
    rtcPeers: RTCPeer[];
    peers: RTCPeerState[];
    messages: Message[];
    userMediaFeedback: UserMediaFeedback;
    userSocketFeedback: UserSocketFeedback;
    onEndCall: () => void;
    onUpdateVideo: () => void;
    onUpdateAudio: () => void;
}

const CallInPage: React.FC<CallInPageProps> = ({
    localId,
    localDeviceId,
    selectedId,
    autoSelectedId,
    localAudioEnabled,
    localVideoEnabled,
    actionsActive,
    localStream,
    rtcPeers,
    peers,
    messages,
    userMediaFeedback,
    onEndCall,
    onUpdateVideo,
    onUpdateAudio,
}) => {
    const [width, height] = useWindowSize();
    const localVideoProps: VideoScreenStyleProps = {
        local: true,
        zIndex: 1,
        iconSize: 'large',
        ...setSizeFromScreenDimConstraint(width, height),
    };

    return (
        <CenterLayout
            maxWidth={false}
            disableGutters={true}
            backgroundColor={theme_vars.DARK_VIDEO_BG}
            overflowY="hidden"
        >
            <TopPanel stream={localStream} videoEnabled={localVideoEnabled} audioEnabled={localAudioEnabled} />

            <SideDrawer active={actionsActive} localDeviceId={localDeviceId} messages={messages} />

            {rtcPeers.length === 0 ? (
                <StreamScreen
                    videoScreenStyleProps={localVideoProps}
                    stream={localStream}
                    videoEnabled={localVideoEnabled}
                    audioEnabled={localAudioEnabled}
                />
            ) : (
                <StreamPeerController rtcPeers={rtcPeers} peerStates={peers} />
            )}

            <StreamActions
                active={actionsActive}
                bottom={4}
                audioEnabled={localAudioEnabled}
                videoEnabled={localVideoEnabled}
                onUpdateAudio={onUpdateAudio}
                onUpdateVideo={onUpdateVideo}
                onEndCall={onEndCall}
            />
        </CenterLayout>
    );
};

export default CallInPage;
