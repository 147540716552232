import React from 'react';
import { Box, IconButton, Theme } from '@material-ui/core';
import { X } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) => ({
    closeButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
}));

interface SideDrawerHeaderProps {
    title: string;
    resetKey: () => void;
}

const SideDrawerHeader: React.FC<SideDrawerHeaderProps> = ({ title, resetKey }) => {
    const classes = useStyles();
    return (
        <Box
            width="100%"
            display="flex"
            alignItems="center"
            style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
            <Box width="100%" display="flex" alignItems="center" pl={2} pr={2} pb={1} pt={1}>
                <span style={{ flex: 1, fontWeight: 500, fontSize: 18 }}>{title}</span>
                <IconButton onClick={resetKey} className={classes.closeButton}>
                    <X size={20} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default SideDrawerHeader;
