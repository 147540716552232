class Routes {
    // Pages
    static readonly landing = '/';
    // Call
    static readonly calls = '/calls';
    static readonly call = '/calls/:id';
    // Help
    static readonly help = '/help';
}

export default Routes;
