import { Box } from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { theme_vars } from '../../constants/theme';

const useStyles = makeStyles((theme) => ({
    messageSenderName: {
        fontSize: 14,
        color: theme.palette.grey['700'],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        textAlign: 'center',
    },
    contentWrapper: {
        '&:hover $actionWrapper': {
            opacity: 1,
        },
    },
    messageBox: {
        maxWidth: '70%',
        padding: theme.spacing(1, 2),
        borderRadius: 18,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        '& a, a:hover, a:active, a:focus, a:visited': {
            color: 'white',
        },
    },
    actionWrapper: {
        opacity: 0,
        [theme.breakpoints.down('sm')]: {
            opacity: 1,
        },
    },
}));

interface MessageBoxProps {
    content: string;
    isMe: boolean;
    senderInfo?: string;
    action?: React.ReactNode;
}

const MessageBox: React.FC<MessageBoxProps> = ({ content, isMe, action }) => {
    const classes = useStyles();
    const { palette } = useTheme();
    const alignment = isMe ? 'flex-end' : 'flex-start';
    const messageBoxStyle = {
        backgroundColor: isMe ? theme_vars.PRIMARY : palette.grey['200'],
        color: isMe ? palette.common.white : palette.common.black,
    };
    const flexDirection = isMe ? 'row-reverse' : 'row';

    return (
        <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={alignment}
            className={classes.contentWrapper}
        >
            <Box
                display={'flex'}
                alignItems="center"
                flexDirection={flexDirection}
                ml={isMe ? 2 : 0}
                mr={isMe ? 0 : 2}
                mt={2}
            >
                <Box mr={1} ml={1} className={classes.messageBox} style={messageBoxStyle}>
                    {content}
                </Box>
                <Box className={classes.actionWrapper}>{action}</Box>
            </Box>
            {/*<span className={classes.messageSenderName}>{senderInfo}</span>*/}
        </Box>
    );
};

export default MessageBox;
