import React from 'react';
import { Picker, PickerProps } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import ManagedInput, { ManagedInputProps } from './ManagedInput';
import { Menu } from '@material-ui/core';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { Smile } from 'react-feather';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { theme_vars } from '../../constants/theme';

const i18n: PickerProps['i18n'] = {
    search: 'Søg',
    notfound: 'Ingen emojies fundet',
    categories: {
        search: 'Søgeresultater',
        recent: 'Mest anvendt',
        people: 'Smileys & Mennesker',
        nature: 'Dyr & Natur',
        foods: 'Mad & Drikke',
        activity: 'Aktivitet',
        places: 'Rejser & Steder',
        objects: 'Objekter',
        symbols: 'Symboler',
        flags: 'Flag',
        custom: 'Custom',
    },
};

const useStyles = makeStyles((theme) => ({
    desktopButton: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const EmojiInput: React.FC<ManagedInputProps> = ({ onChange, onSubmit, value, InputProps }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleShow: IconButtonProps['onClick'] = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const endAdornment = (
        <IconButton className={classes.desktopButton} onClick={handleShow} size="small">
            <Smile size={20} />
        </IconButton>
    );

    return (
        <>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    disablePadding: true,
                }}
            >
                <Picker
                    title="Vælg en emoji…"
                    emoji="point_up"
                    enableFrequentEmojiSort={false}
                    native={true}
                    useButton={false}
                    emojiSize={20}
                    theme="light"
                    color={theme_vars.PRIMARY}
                    i18n={i18n}
                    emojiTooltip={false}
                    onSelect={(emoji: unknown) => {
                        onChange(value + (emoji as { native: string })?.native);
                    }}
                />
            </Menu>
            <ManagedInput
                value={value}
                onChange={onChange}
                onSubmit={onSubmit}
                InputProps={{ endAdornment, ...InputProps }}
            />
        </>
    );
};

export default EmojiInput;
