import local_sound_video_issue from './local_sound_video_issue';
import local_video_issue from './local_video_issue';
import connection_issue from './connection_issue';

export const articleFiles: { [filepath: string]: { title: string; source: string } } = {
    local_sound_video_issue: { title: 'Min lyd og/eller billede virker ikke', source: local_sound_video_issue },
    local_video_issue: { title: 'Mit kamera virker ikke efter jeg har tilladt det', source: local_video_issue },
    connection_issue: { title: 'Jeg kan ikke komme ind i videosamtalen', source: connection_issue },
};

export const articles = ['local_sound_video_issue', 'local_video_issue', 'connection_issue'];
