import React, { useState } from 'react';
import { InputProps } from '@material-ui/core/Input/Input';
import { InputBase } from '@material-ui/core';

export interface ManagedInputProps {
    value: string;
    onSubmit: (text: string) => void;
    onChange: (text: string) => void;
    InputProps?: Partial<InputProps>;
}

const ManagedInput: React.FC<ManagedInputProps> = ({ value = '', onSubmit, onChange, InputProps }) => {
    const [shift, setShift] = useState(false);
    const [ctrl, setCtrl] = useState(false);

    const handleKeyDown: InputProps['onKeyDown'] = (e) => {
        if (e.key === 'Enter' && !shift && !ctrl) {
            e.preventDefault();
            if (value.trim().length === 0) {
                onChange('');
                return;
            }
            onSubmit(value);
            onChange('');
        }
        if (e.key === 'Shift') {
            setShift(true);
        } else if (e.key === 'Control') {
            setCtrl(true);
        }
    };

    const handleKeyUp: InputProps['onKeyUp'] = (e) => {
        if (e.key === 'Shift') {
            setShift(false);
        } else if (e.key === 'Control') {
            setCtrl(false);
        }
    };

    const handleChange: InputProps['onChange'] = (e) => onChange(e.target.value);

    // TODO: Only remove auto focus on mobil device
    return (
        <InputBase
            style={{ padding: 0 }}
            value={value}
            fullWidth
            multiline
            rowsMax={20}
            placeholder="Skriv her ..."
            name="content"
            type="text"
            id="content"
            autoComplete="text"
            autoFocus={false}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            {...InputProps}
        />
    );
};

export default ManagedInput;
