import { RefObject, useLayoutEffect, useState } from 'react';

export const useDimHook = (containerRef: RefObject<HTMLDivElement>) => {
    // TODO: USE WITH ASPECT!
    const [dim, setDim] = useState<{ width: number | string; height: number | string }>({
        width: 'unset',
        height: 'unset',
    });

    const handleSetDim = () => {
        if (!containerRef.current?.parentElement) return { width: 'unset', height: 'unset' };
        const width = window.innerWidth;
        // const height = window.innerHeight;
        const containerWidth = containerRef.current.parentElement?.clientWidth;
        const containerHeight = containerRef.current.parentElement?.clientHeight;
        return width > containerHeight
            ? {
                  width: containerWidth,
                  height: 'unset',
              }
            : {
                  width: 'unset',
                  height: containerHeight,
              };
    };

    // TODO: Fix depcs

    useLayoutEffect(() => {
        setDim(handleSetDim());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        const resizeListener = () => {
            setDim(handleSetDim());
        };
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return dim;
};

// export const useWidthAndHeight = () => {
//     const [width, setWidth] = useState<{ width: number; height: number }>({width: window.innerWidth});
//     const resizeListener = () => {
//         setWidth(window.innerWidth);
//     }
//     useLayoutEffect(() => {
//         window.addEventListener("resize", resizeListener);
//         return () => {
//             window.removeEventListener("resize", resizeListener);
//         }
//     }, [])
//
//     return width;
// }

export const useHeight = () => {
    const [height, setHeight] = useState<number>(window.innerHeight);
    const resizeListener = () => {
        setHeight(window.innerHeight);
    };
    useLayoutEffect(() => {
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return height;
};

export const useWidth = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const resizeListener = () => {
        setWidth(window.innerWidth);
    };
    useLayoutEffect(() => {
        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return width;
};
