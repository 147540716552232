import { ThemeOptions } from '@material-ui/core';

const HIDE_PANEL_TIMEOUT = 5000;
const BLACK_OPAQUE_BG = 'rgba(0,0,0,0.4)';
const RED_OPAQUE_BG = 'rgba(219, 68, 55,0.9)';
const WHITE_OPAQUE_BG = 'rgba(255,255,255,0.9)';
const PRIMARY = '#7DB942';
const LIGHT_VIDEO_BG = '#292828';
const DARK_VIDEO_BG = '#111111';

const FAB_SHADOW =
    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)';
const PAPER_SHADOW_1 =
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)';
const PAPER_SHADOW_2 =
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)';

export const defaultThemeConfig: ThemeOptions = {
    palette: {
        primary: {
            main: PRIMARY,
            contrastText: 'white',
        },
    },
};

const SIDEBAR_WIDTH = {
    width: 300,
};

export const theme_vars = {
    HIDE_PANEL_TIMEOUT,
    BLACK_OPAQUE_BG,
    RED_OPAQUE_BG,
    WHITE_OPAQUE_BG,
    FAB_SHADOW,
    PAPER_SHADOW_1,
    PAPER_SHADOW_2,
    SIDEBAR_WIDTH,
    PRIMARY,
    LIGHT_VIDEO_BG,
    DARK_VIDEO_BG,
};
