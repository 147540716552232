import React, { useState } from 'react';
import { Box, Button, TextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import Routes from '../../constants/routes';
import CenterLayout from '../../components/CenterLayout';
import { Video } from 'react-feather';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(6),
        fontSize: 24,
    },
    buttonList: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        width: 400,
        maxWidth: '100vw',
    },
    button: {
        padding: '12px 18px',
        '& span': {
            paddingRight: 8,
        },
        '& svg': {
            marginBottom: 2,
        },
    },
}));

const LandingPage: React.FC = () => {
    const classes = useStyles();
    const [call, setCall] = useState('');
    const history = useHistory();

    const handleGoToCallClick = () => {
        const url = call.substring(call.lastIndexOf('/') + 1);
        history.push(Routes.call.replace(':id', url));
    };

    const handleChange: TextFieldProps['onChange'] = (event) => setCall(event.target.value);

    return (
        <CenterLayout>
            <p className={classes.title}>Forbind til samtale</p>
            <form onSubmit={handleGoToCallClick}>
                <Box
                    className={classes.buttonList}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="100vw"
                    flexWrap="wrap"
                >
                    <TextField
                        value={call}
                        onChange={handleChange}
                        label="Indsæt videolink eller kode"
                        variant="outlined"
                        className={classes.input}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={call === ''}
                        className={classes.button}
                    >
                        <span>Deltag</span>
                        <Video size={20} />
                    </Button>
                </Box>
            </form>
        </CenterLayout>
    );
};

export default LandingPage;
