import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CenterLayout from '../../components/CenterLayout';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    title: {
        marginBottom: theme.spacing(6),
        fontSize: 24,
    },
    buttonList: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

interface CallLeavePageProps {
    onBack: () => void;
    redirect: string | undefined;
}

const CallLeavePage: React.FC<CallLeavePageProps> = ({ onBack, redirect }) => {
    const classes = useStyles();

    const handleClickEndCall = async () => {
        redirect && window.location.replace(redirect);
    };

    return (
        <CenterLayout>
            <p className={classes.title}>Du forlod opkaldet</p>
            <Box
                className={classes.buttonList}
                display="flex"
                justifyContent="center"
                alignItems="center"
                maxWidth="100vw"
                flexWrap="wrap"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    style={{ padding: '12px 18px' }}
                    onClick={onBack}
                >
                    Tilbage til opkald
                </Button>
                {redirect && (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ padding: '12px 18px' }}
                        onClick={handleClickEndCall}
                    >
                        Besvar spørgeskema
                    </Button>
                )}
            </Box>
        </CenterLayout>
    );
};

export default CallLeavePage;
