import tillad_mikrofon_og_kamera_fejl from './images/tillad_mikrofon_og_kamera_fejl.png';
import tillad_mikrofon_og_kamera_1 from './images/tillad_mikrofon_og_kamera_1.png';
import tillad_mikrofon_og_kamera_2 from './images/tillad_mikrofon_og_kamera_2.png';

export default `
## Min lyd og/eller billede virker ikke?

Bliver du mødt med beskeden som kan ses forneden, betyder det at du ikke har tilladt din browser at bruge pc'ens mikrofon og kamera. For at tillade begge kan du følge anvisningen under billedet, dog bedes du først kontrollere at du ikke benytter dig at fjernskrivebrodet.

![Billed af fejlen "Vi kunne ikke få adgang til dit kamera".](${tillad_mikrofon_og_kamera_fejl})

1. Klik på kamera ikonet, som befinder sig til højre for adressebaren, såfremt du benytter Google Chrome.

![Klik på kamera ikonet ved siden af browserens adressefelt.](${tillad_mikrofon_og_kamera_1})

2. Klik cirklen udfra "Tillad altid, at https://video.headspace.dk har adgang til dit kamera og din mikrofon" og derefter på "Udfør".

![Klik cirklen udfra "Tillad altid, at https://video.headspace.dk har adgang til dit kamera og din mikrofon" og derefter på "Udfør"](${tillad_mikrofon_og_kamera_2})

3. Klik nu på "PRØV IGEN", så opdaterer siden og de andre deltagere i samtalen kan nu både se og høre dig.
`;
