import React from 'react';
import { Box, Container, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHeight } from '../hooks/dimHooks';

interface CenterLayoutStyleProps {
    backgroundColor: string;
    height?: number;
    topGutter?: number;
    overflowX: 'hidden' | 'unset';
    overflowY: 'hidden' | 'unset';
    phoneScroll: boolean;
}

const useStyles = makeStyles<Theme, CenterLayoutStyleProps>((theme: Theme) => ({
    root: {
        backgroundColor: (props) => props.backgroundColor,
        height: (props) => props.height ?? 'unset',
        maxHeight: (props) => props.height ?? '100vh',
        width: '100%',
        position: 'relative',
        paddingTop: (props) => props.topGutter,
        overflowX: (props) => props.overflowX,
        overflowY: (props) => props.overflowY,
        [theme.breakpoints.down('md')]: {
            height: (props) => (props.phoneScroll ? 'unset' : props.height),
            maxHeight: (props) => (props.phoneScroll ? 'unset' : props.height),
        },
    },
    title: {
        marginBottom: theme.spacing(4),
        fontSize: 24,
    },
}));

interface CenterLayoutProps {
    backgroundColor?: string;
    overflowX?: 'hidden' | 'unset';
    overflowY?: 'hidden' | 'unset';
    disableGutters?: boolean;
    topGutter?: number;
    height?: number | string;
    phoneScroll?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const CenterLayout: React.FC<CenterLayoutProps> = ({
    children,
    backgroundColor = 'white',
    overflowX = 'hidden',
    overflowY = 'unset',
    maxWidth = 'md',
    topGutter,
    disableGutters = false,
    phoneScroll = false,
}) => {
    const height = useHeight(); // useWindowHeight();
    const classes = useStyles({ height, backgroundColor, topGutter, overflowX, overflowY, phoneScroll });

    return (
        <Box className={classes.root} display="flex" justifyContent="center" alignItems="center">
            <Container maxWidth={maxWidth} style={{ height: '100%' }} disableGutters={disableGutters}>
                <Box
                    height="100%"
                    position="relative"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    {children}
                </Box>
            </Container>
        </Box>
    );
};

export default CenterLayout;
