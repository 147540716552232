import React from 'react';

const calculateVideoSize = (number: number, index: number, rows: number) => {
    const oddNumber = Boolean(number % rows);
    const oddRowMaxIndex = Math.floor(number / rows) - 1;

    const height = 100 / rows + '%';

    if (oddNumber) {
        const oddRowNumber = oddRowMaxIndex + 1;
        if (index <= oddRowMaxIndex) {
            const width = 100 / oddRowNumber + '%';
            return { width, height };
        }

        const normalRowNumber = (number - oddRowNumber) / (rows - 1);
        const width = 100 / normalRowNumber + '%';
        return { width, height };
    }

    const width = 100 / (number / rows) + '%';
    return { width, height };
};

const getVideoSizeStyle = (number: number, index: number, horizontal: boolean): React.CSSProperties => {
    if (horizontal) {
        const rows = number < 3 ? 1 : number < 9 ? 2 : 3;

        return calculateVideoSize(number, index, rows);
    }

    const overLimit = number > 4;
    const rows = number === 1 ? 1 : !overLimit ? 2 : 1;

    if (overLimit) return { width: '100%', height: '100%' };

    return calculateVideoSize(number, index, rows);
};

export default getVideoSizeStyle;
