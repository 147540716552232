import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface ShadowOverlayStyleProps {
    top: boolean;
    height: number;
}

const useStyles = makeStyles<Theme, ShadowOverlayStyleProps>(() => ({
    overlay: {
        position: 'absolute',
        height: (props) => props.height,
        opacity: 0.6,
        zIndex: 1,
        top: (props) => (props.top ? 0 : 'unset'),
        bottom: (props) => (!props.top ? 0 : 'unset'),
        width: '100%',
        transform: (props) => (props.top ? 'unset' : 'rotateZ(180deg)'),
        backgroundImage: 'linear-gradient(top,rgba(0,0,0,0.7) 0,rgba(0,0,0,0.3) 40%,rgba(0,0,0,0) 100%)',
    },
}));

interface ShadowOverlayProps {
    top?: boolean;
    height?: number;
}

const ShadowOverlay: React.FC<ShadowOverlayProps> = ({ top = true, height = 60 }) => {
    const classes = useStyles({ top, height });
    return <div className={classes.overlay} />;
};

export default ShadowOverlay;
