import React from 'react';
import { Box } from '@material-ui/core';
import StreamScreen from './VideoStream/StreamScreen';
import { theme_vars } from '../constants/theme';
import ShadowOverlay from './ShadowOverlay';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    topBarWrapper: {
        boxShadow: theme_vars.FAB_SHADOW,
        zIndex: 10,
        position: 'absolute',
        top: 16,
        right: 16,
        height: 90,
        width: 120,
        borderRadius: 5,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface TopPanelProps {
    stream: MediaStream;
    videoEnabled: boolean;
    audioEnabled: boolean;
}

const TopPanel: React.FC<TopPanelProps> = ({ stream, audioEnabled, videoEnabled }) => {
    const classes = useStyles();
    return (
        <Box height={90} className={classes.topBarWrapper}>
            <span style={{ zIndex: 2, position: 'absolute', bottom: 8, left: 8, color: 'white', fontSize: 14 }}>
                dig
            </span>
            <StreamScreen
                videoScreenStyleProps={{
                    local: true,
                    iconSize: 'small',
                    width: '100%',
                    zIndex: 1,
                }}
                stream={stream}
                videoEnabled={videoEnabled}
                audioEnabled={audioEnabled}
            />
            <ShadowOverlay height={40} />
            <ShadowOverlay top={false} height={40} />
        </Box>
    );
};

export default TopPanel;
