const getAspectSize = (x: number, y: number): { nx: number; ny: number } => {
    const horizontal = x >= y;

    if (horizontal) {
        const nx = y * 1.78; // (16 / 9);

        if (nx > x) {
            const ny = x * 0.5625; // (9 / 16)
            return { nx: x, ny };
        }

        return { nx, ny: y };
    }

    return { nx: x, ny: y };
};

export default getAspectSize;
