import { useState, useEffect } from 'react';

const useInnerWindowSize = () => {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    // const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    useEffect(() => {
        const resizeWidth = () => setInnerWidth(window.innerWidth);
        window.addEventListener('resize', resizeWidth);
        return () => {
            window.removeEventListener('resize', resizeWidth);
        };
    }, []);

    // useEffect(() => {
    //     const resizeHeight = () => setInnerHeight(window.innerHeight);
    //     window.addEventListener('resize', resizeHeight);
    //     return () => {
    //         window.removeEventListener('resize', resizeHeight);
    //     };
    // }, []);

    return { innerWidth, innerHeight: window.innerHeight };
};

export default useInnerWindowSize;
