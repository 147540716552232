import React, { useRef } from 'react';
import StreamScreen from './StreamScreen';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme_vars } from '../../constants/theme';
import { UserMediaFeedback } from '../../models/Feedback';
import ShadowOverlay from '../ShadowOverlay';
import StreamActions from './StreamActions';
import { useDimHook } from '../../hooks/dimHooks';

const useStyles = makeStyles<Theme>((theme) => ({
    root: {
        background: theme_vars.DARK_VIDEO_BG,
        overflow: 'hidden',
        borderRadius: 5,
        position: 'relative',
        boxShadow: theme_vars.PAPER_SHADOW_2,
        height: 400,
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    feedbackMessageWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 3,
    },
    feedbackMessage: {
        color: 'white',
        fontSize: 22,
        textAlign: 'center',
    },
}));

interface CallStreamProps {
    id?: string;
    userMediaFeedback: UserMediaFeedback;
    videoEnabled: boolean;
    audioEnabled: boolean;
    stream?: MediaStream;
    selectedId?: string;
    autoSelectedId?: string;
    onUpdateAudio: () => void;
    onUpdateVideo: () => void;
    local?: boolean | false;
}

const StreamCardWrapper: React.FC<CallStreamProps> = ({
    userMediaFeedback,
    videoEnabled,
    audioEnabled,
    stream,
    onUpdateAudio,
    onUpdateVideo,
    local = false,
}) => {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);
    const dim = useDimHook(containerRef);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxHeight="100%"
            position="relative"
            className={classes.root}
        >
            <div ref={containerRef} />

            {userMediaFeedback.title && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    className={classes.feedbackMessageWrapper}
                >
                    <span className={classes.feedbackMessage}>{userMediaFeedback.title}</span>
                </Box>
            )}

            <StreamActions
                animate={false}
                bottom={2}
                audioEnabled={audioEnabled}
                videoEnabled={videoEnabled}
                onUpdateAudio={onUpdateAudio}
                onUpdateVideo={onUpdateVideo}
            />

            {userMediaFeedback.success && (
                <StreamScreen
                    videoScreenStyleProps={{
                        local: local,
                        iconSize: 'small',
                        videoOverlay: theme_vars.DARK_VIDEO_BG,
                        zIndex: 1,
                        ...dim,
                    }}
                    icons={false}
                    stream={stream}
                    videoEnabled={stream?.active ? videoEnabled : false}
                    audioEnabled={stream?.active ? audioEnabled : false}
                />
            )}

            <ShadowOverlay />
            <ShadowOverlay top={false} />
        </Box>
    );
};

export default StreamCardWrapper;
