import React from 'react';
import { Box, Fab, Link, Tooltip } from '@material-ui/core';
import headspace_logo from '../../assets/headspace_logo.gif';
import Routes from '../../constants/routes';
import { LifeBuoy } from 'react-feather';

const CallEnterPageHeader: React.FC = () => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={2} pb={2} maxHeight={50} height={50}>
            <Link href="https://www.headspace.dk" target="_blank">
                <img src={headspace_logo} alt="headspace" style={{ height: 35 }} />
            </Link>
            <Link href={Routes.help} target="_blank">
                <Tooltip title={<span style={{ fontSize: 14 }}>Hjælp</span>}>
                    <Fab color={'primary'} size="large">
                        <LifeBuoy color="white" />
                    </Fab>
                </Tooltip>
            </Link>
        </Box>
    );
};

export default CallEnterPageHeader;
