import SimplePeer from 'simple-peer';
import { Harker } from 'hark';

export interface RTCPeer {
    id: string;
    rtc: SimplePeer.Instance;
    stream: MediaStream | undefined;
    hark: Harker | undefined;
}
export const emptyRTCPeer = {
    id: '',
    stream: undefined,
    hark: undefined,
};
