import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, ClickAwayListener, Fab, Link, Theme, Tooltip } from '@material-ui/core';
import { theme_vars } from '../../constants/theme';
import { LifeBuoy, Send } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import ChatDisplay from './ChatDisplay';
import SideDrawerHeader from './SideDrawerHeader';
import Routes from '../../constants/routes';
import SideDrawerIcon from './SideDrawerIcon';
import { Message } from '../../models/Message';
import { Active } from '../../models/Active';

type SideDrawerStyleProps = Active & {
    drawerKey: number | undefined;
};

const useStyles = makeStyles<Theme, SideDrawerStyleProps>((theme) => ({
    root: {
        position: 'absolute',
        zIndex: 100,
        height: 'calc(100% - 16px)',
        bottom: 0,
        left: 0,
        [theme.breakpoints.up('sm')]: {
            left: (props) => (props.active || props.drawerKey !== undefined ? 0 : -100),
        },
        display: 'flex',
        alignItems: 'flex-start',
        transition: 'left 0.4s ease',
    },
    display: {
        overflow: 'hidden',
        position: 'relative',
        zIndex: 15,
        height: 'calc(100% - 16px)',
        width: theme_vars.SIDEBAR_WIDTH.width,
        boxShadow: theme_vars.FAB_SHADOW,
        background: 'white',
        marginLeft: (props) => (props.drawerKey !== undefined ? 8 : -theme_vars.SIDEBAR_WIDTH.width),
        borderRadius: 8,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100vw - 16px)',
            marginLeft: (props) => (props.drawerKey !== undefined ? 8 : '-100vw'),
        },
        transition: 'margin 0.3s ease',
    },
    mobileFabButtonWrapper: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            zIndex: 10,
            marginBottom: 4,
        },
    },
    fabButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        background: theme_vars.BLACK_OPAQUE_BG,
        color: 'white',
    },
    activeFabButton: {
        color: 'black',
        background: theme_vars.WHITE_OPAQUE_BG,
    },
}));

export interface SideDrawerProps {
    active: boolean;
    messages: Message[];
    localDeviceId?: string;
}

const SideDrawer: React.FC<SideDrawerProps> = ({ active, messages, localDeviceId }) => {
    const [lastMessageRead, setLastMessageRead] = useState(true);
    const [lastMessages, setLastMessages] = useState<Message[]>([]);
    const [drawerKey, setDrawerKey] = useState<number | undefined>();
    const classes = useStyles({ active, drawerKey });

    useEffect(() => {
        if (messages.length === lastMessages.length) return;
        const messageRead =
            messages.length === 0
                ? true
                : messages[messages.length - 1].deviceId === localDeviceId
                ? true
                : drawerKey !== undefined;
        setLastMessageRead(messageRead);
        setLastMessages(messages);
    }, [messages, lastMessages, localDeviceId, drawerKey]);

    const handleOpenCloseDrawer = (key: number | undefined) => {
        if (drawerKey === key) {
            setDrawerKey(undefined);
            return;
        }
        setLastMessageRead(true);
        setDrawerKey(key);
    };

    return (
        <ClickAwayListener onClickAway={() => handleOpenCloseDrawer(drawerKey)}>
            <Box className={classes.root}>
                <Box className={classes.display}>
                    <Box display="flex" flexDirection="column" height="100%" style={{ position: 'relative' }}>
                        <SideDrawerHeader title="Chat" resetKey={() => handleOpenCloseDrawer(drawerKey)} />
                        <ChatDisplay />
                    </Box>
                </Box>

                <Box className={classes.mobileFabButtonWrapper} display="flex" flexDirection="column">
                    <Link href={Routes.help} target="_blank">
                        <Tooltip
                            leaveTouchDelay={0}
                            leaveDelay={0}
                            enterDelay={500}
                            title={<span style={{ fontSize: 14 }}>Hjælp</span>}
                        >
                            <Fab className={classes.fabButton}>
                                <SideDrawerIcon icon={<LifeBuoy />} badgeInvisible={true} />
                            </Fab>
                        </Tooltip>
                    </Link>

                    <Tooltip
                        leaveTouchDelay={0}
                        leaveDelay={0}
                        enterDelay={500}
                        title={<span style={{ fontSize: 14 }}>Chat</span>}
                    >
                        <Fab
                            className={clsx(classes.fabButton, 1 === drawerKey && classes.activeFabButton)}
                            onClick={() => handleOpenCloseDrawer(1)}
                        >
                            <SideDrawerIcon icon={<Send />} badgeInvisible={lastMessageRead} />
                        </Fab>
                    </Tooltip>
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

export default SideDrawer;
