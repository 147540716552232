export type UserSocketFeedback = {
    loading: boolean;
    success: boolean;
    title?: string;
};

export const initialUserSocketFeedback: UserSocketFeedback = {
    loading: true,
    success: false,
    title: undefined,
};

export type UserMediaFeedback = {
    loading: boolean;
    success: boolean;
    title?: string;
    code?: UserMediaFeedbackCode;
};

export enum UserMediaFeedbackCode {
    'initial',
    'cameraOff',
    'notWebRTCCompatible',
    'permissions',
    'empty',
}

export const userMediaFeedbackMap: { [p: string]: UserMediaFeedback } = {
    [UserMediaFeedbackCode.initial]: {
        loading: true,
        success: false,
        title: 'Klargør ...',
        code: UserMediaFeedbackCode.initial,
    },
    [UserMediaFeedbackCode.notWebRTCCompatible]: {
        loading: false,
        success: false,
        title: 'Din browser understøtter ikke videosamtaler',
        code: UserMediaFeedbackCode.notWebRTCCompatible,
    },
    [UserMediaFeedbackCode.permissions]: {
        loading: false,
        success: false,
        title: 'Vi kunne ikke få adgang til dit kamera',
        code: UserMediaFeedbackCode.permissions,
    },
    [UserMediaFeedbackCode.cameraOff]: {
        loading: false,
        success: true,
        title: 'Kameraet er slukket',
        code: UserMediaFeedbackCode.cameraOff,
    },
    [UserMediaFeedbackCode.empty]: {
        loading: false,
        success: true,
        title: undefined,
        code: UserMediaFeedbackCode.empty,
    },
};
