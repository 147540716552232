import React, { ReactNode } from 'react';
import { Badge } from '@material-ui/core';

interface SideDrawerIconProps {
    badgeInvisible?: boolean;
    icon: ReactNode;
}

const SideDrawerIcon: React.FC<SideDrawerIconProps> = ({ badgeInvisible, icon }) => {
    if (badgeInvisible === undefined) return <>{icon}</>;
    return (
        <Badge
            color="secondary"
            variant="dot"
            overlap="circle"
            badgeContent=" "
            invisible={badgeInvisible}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {icon}
        </Badge>
    );
};

export default SideDrawerIcon;
