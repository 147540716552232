import { v4 as uuidv4 } from 'uuid';

export const getOrSetDeviceUUID = (callId: string): string => {
    let uuid = localStorage.getItem(callId);
    if (uuid === null) {
        uuid = uuidv4();
        localStorage.setItem(callId, uuid);
    }
    return uuid;
};
