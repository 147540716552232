import React, { useEffect, useState } from 'react';
import { RTCPeerState } from '../../models/RTCPeerState';
import { RTCPeer } from '../../models/RTCPeer';
import useInnerWindowSize from '../../hooks/useInnerWindowSize';
import AspectRatioBox from '../AspectRatioBox';
import getVideoSizeStyle from '../../lib/getVideoSizeStyle';
import StreamPeerBox from './StreamPeerBox';
import { MOBILE_VIEWABLE_PEER_LIMIT } from '../../constants/config';

interface StreamPeerControllerProps {
    peerStates: RTCPeerState[];
    rtcPeers: RTCPeer[];
}

const StreamPeerController: React.FC<StreamPeerControllerProps> = ({ rtcPeers, peerStates }) => {
    const [speakingPeer, setSpeakingPeer] = useState<RTCPeer>(rtcPeers[0]);
    const { innerWidth, innerHeight } = useInnerWindowSize();

    const horizontal = innerWidth >= innerHeight;
    const harkSpeaker = !horizontal && rtcPeers.length > MOBILE_VIEWABLE_PEER_LIMIT;

    useEffect(() => {
        rtcPeers.forEach((peer) => {
            peer.hark?.on('speaking', () => {
                setSpeakingPeer(peer);
            });
        });
    }, [rtcPeers]);

    if (harkSpeaker) {
        const peerState = peerStates.find((state) => state.id === speakingPeer.id);

        return (
            <AspectRatioBox x={innerWidth} y={innerHeight}>
                <StreamPeerBox width="100%" height="100%" peer={speakingPeer} state={peerState} />
            </AspectRatioBox>
        );
    }

    return (
        <AspectRatioBox x={innerWidth} y={innerHeight}>
            {rtcPeers.map((rtcPeer: RTCPeer, index) => {
                const peerState = peerStates.find((p) => p.id === rtcPeer.id);
                const { width, height } = getVideoSizeStyle(rtcPeers.length, index, horizontal);
                return (
                    <StreamPeerBox key={rtcPeer.id} peer={rtcPeer} state={peerState} width={width} height={height} />
                );
            })}
        </AspectRatioBox>
    );
};

export default StreamPeerController;
