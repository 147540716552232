import React from 'react';
import { Box, Grid, Link, List, ListItem, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CenterLayout from '../../components/CenterLayout';
import { articleFiles, articles } from './articles';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        width: '100vw',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    title: {
        marginTop: theme.spacing(0),
        fontSize: 24,
    },
    accordion: {
        padding: '0 16px',
        boxShadow: 'unset',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        '&:before': {
            background: 'white',
        },
        '&:first-child': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
        },
        '&:last-child': {
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
        },
    },
    markdown: {
        maxWidth: 800,
        marginBottom: theme.spacing(3),
        '& img': {
            maxWidth: '100%',
            textAlign: 'center',
        },
    },
}));

const SupportPage: React.FC = () => {
    const [articleIndex, setArticleIndex] = React.useState<string>(articles[0]);
    const classes = useStyles();

    return (
        <CenterLayout maxWidth="lg">
            <Box height="100%">
                <Box pt={8}>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <Box style={{ minWidth: '100%' }}>
                                <p className={classes.title}>Hvad kan vi hjælpe dig med?</p>
                                <List style={{ paddingBottom: 16 }}>
                                    {articles.map((article) => (
                                        <ListItem
                                            button
                                            key={article}
                                            selected={article === articleIndex}
                                            onClick={() => setArticleIndex(article)}
                                        >
                                            {articleFiles[article].title}
                                        </ListItem>
                                    ))}
                                </List>
                                <Paper variant="outlined">
                                    <Box p={2} display="flex" alignItems="center" justifyContent="center">
                                        <span>
                                            Kontakt os på:{' '}
                                            <Link href="mailto:support@innosocial.dk">support@innosocial.dk</Link>
                                        </span>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Paper variant="outlined" style={{ minWidth: '100%', minHeight: '100%' }}>
                                <Box pl={2} pr={2}>
                                    <Box className={classes.markdown}>
                                        <ReactMarkdown source={articleFiles[articleIndex].source} />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </CenterLayout>
    );
};

export default SupportPage;
