import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { callActions } from '../../store/CallState';
import { Send } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import ScrollableFeed from 'react-scrollable-feed';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EmojiInput from './EmojiInput';
import MessageBox from './MessageBox';
import { theme_vars } from '../../constants/theme';

const useStyles = makeStyles((theme) => ({
    feed: {
        padding: theme.spacing(0, 1),
    },
    sendIcon: {
        marginBottom: 8,
        marginTop: 8,
        marginLeft: 16,
        marginRight: 16,
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 6,
            marginTop: 6,
        },
    },
}));

const ChatDisplay: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [text, setText] = useState<string>('');
    const { localDeviceId, messages } = useSelector((state: AppState) => state.call);

    const handleCreateMessage = () => {
        dispatch(callActions.createMessage(text));
        setText('');
    };

    return (
        <>
            <ScrollableFeed className={classes.feed}>
                {messages.map((message) => {
                    const isMe = message.deviceId === localDeviceId;
                    return <MessageBox key={message.id} isMe={isMe} content={message.content} />;
                })}
                <span style={{ height: 8, display: 'block' }} />
            </ScrollableFeed>
            <Box
                display="flex"
                alignItems="flex-end"
                style={{
                    padding: '8px 0px 8px 8px',
                    background: 'white',
                    borderTop: '1px solid rgba(224, 224, 224, 1)',
                }}
            >
                <Box style={{ background: '#E2E3E4', borderRadius: 20, padding: '8px 16px', width: '100%' }}>
                    <EmojiInput value={text} onSubmit={handleCreateMessage} onChange={(text) => setText(text)} />
                </Box>
                <IconButton onClick={handleCreateMessage} disabled={!text} className={classes.sendIcon} size="small">
                    <Send color={!text ? 'grey' : theme_vars.PRIMARY} size={20} />
                </IconButton>
            </Box>
        </>
    );
};

export default ChatDisplay;
