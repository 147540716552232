import React, { useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { setVideoElement } from '../../lib/video';
import { MicOff, User } from 'react-feather';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { theme_vars } from '../../constants/theme';

export interface VideoScreenStyleProps {
    local: boolean;
    width?: string | number;
    height?: string | number;
    maxWidth?: string | number;
    maxHeight?: string | number;
    right?: number;
    bottom?: number;
    iconSize: 'small' | 'large';
    zIndex: number;
    videoOverlay?: string;
}

const useStyles = makeStyles<Theme, VideoScreenStyleProps>((theme) => ({
    video: {
        transform: (props) => (props.local ? 'rotateY(180deg)' : ''),
        height: (props) => props.height,
        width: (props) => props.width,
        maxHeight: (props) => props.maxHeight,
        maxWidth: (props) => props.maxWidth,
    },
    videoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        width: 'unset',
    },
    icon: {
        position: 'absolute',
        zIndex: 2,
    },
    videoIcon: {
        width: (props) => (props.iconSize === 'small' ? 20 : 80),
        height: (props) => (props.iconSize === 'small' ? 20 : 80),
    },
    videoIconWrapper: {
        borderRadius: '50%',
        width: (props) => (props.iconSize === 'small' ? 50 : 150),
        height: (props) => (props.iconSize === 'small' ? 50 : 150),
        backgroundColor: '#4f4e4e',
        boxShadow: theme_vars.PAPER_SHADOW_1,
    },
    videoOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: theme_vars.LIGHT_VIDEO_BG,
        zIndex: 1,
    },
    soundIcon: {
        bottom: theme.spacing(1),
        right: theme.spacing(1),
        height: (props) => (props.iconSize === 'small' ? 15 : 50),
    },
}));

interface VideoScreenProps {
    videoScreenStyleProps: VideoScreenStyleProps;
    icons?: boolean;
    stream?: MediaStream;
    muted?: boolean;
    videoEnabled: boolean;
    audioEnabled: boolean;
}

const StreamScreen: React.FC<VideoScreenProps> = ({
    videoScreenStyleProps,
    icons = true,
    stream,
    videoEnabled,
    audioEnabled,
    muted = false,
}) => {
    const classes = useStyles(videoScreenStyleProps);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!stream) return;
        setVideoElement(videoRef, stream);
    }, [stream]);

    return (
        <Box className={classes.videoWrapper}>
            {!videoEnabled && icons && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={clsx(classes.icon, classes.videoIconWrapper)}
                >
                    <User color="white" className={classes.videoIcon} />
                </Box>
            )}
            {!videoEnabled && <Box className={classes.videoOverlay} />}
            {!audioEnabled && icons && <MicOff color="white" className={clsx(classes.icon, classes.soundIcon)} />}
            <video
                className={classes.video}
                muted={videoScreenStyleProps.local || muted}
                ref={videoRef}
                playsInline
                autoPlay
            />
        </Box>
    );
};

export default StreamScreen;
