import packageJson from '../../package.json';

export const NODE_ENV = process.env.NODE_ENV;

const webRTCConfig: RTCConfiguration = {
    iceServers: [
        { urls: 'stun:stun.innosocial.dk' },
        {
            urls: 'turn:turn.innosocial.dk',
            username: 'innosocial',
            credential: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCarxCJ7uFx6W1lVo4Rp8UVFAFd',
        },
    ],
};

export const initialStreamConstraints = {
    audio: true,
    width: 720,
    height: 720,
    video: {
        facingMode: 'user',
    },
};

export const MOBILE_VIEWABLE_PEER_LIMIT = 2;
export const DESKTOP_VIEWABLE_PEER_LIMIT = 12;

export const API_ENV: string | undefined = process.env.REACT_APP_API_ENV || 'development';

export const mobileMediaQuery = '(max-width: 768px)';
export const isProductionMode = API_ENV === 'production';
export const isDevelopmentMode = API_ENV === 'development';
export const isLocalhostMode = API_ENV === 'localhost';

const PROD_CLIENT = 'https://video.innosocial.dk';
// TODO: add dev api
const DEV_CLIENT = 'https://video.innosocial.dk';
const LOCAL_CLIENT = 'http://localhost:5000';
export const CLIENT_URL = isProductionMode ? PROD_CLIENT : isLocalhostMode ? LOCAL_CLIENT : DEV_CLIENT;

export const config = {
    name: packageJson.name,
    version: packageJson.version,
    webRTCConfig: webRTCConfig,
    NODE_ENV: NODE_ENV,
    HOST: CLIENT_URL,
};
