export interface Message {
    id: string;
    callerId: string;
    deviceId: string;
    content: string;
    createdAt: Date;
}
export const emptyMessage: Message = {
    id: '',
    callerId: '',
    deviceId: '',
    content: '',
    createdAt: new Date(),
};
