import React from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, Link, Paper } from '@material-ui/core';
import { Video } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import { UserSocketFeedback } from '../../models/Feedback';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(4),
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2, 0),
        },
    },
    title: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
        fontSize: 28,
    },
    subTitle: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
        fontSize: 17,
    },
    text: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
        color: theme.palette.grey['A700'],
    },
    textBox: {
        width: 400,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            padding: 0,
        },
    },
    disclaimerText: {
        margin: 0,
        color: theme.palette.grey['A700'],
        fontSize: 13,
    },
    checkBox: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
}));

interface CallEnterInfoPanelProps {
    userSocketFeedback: UserSocketFeedback;
    join: () => void;
}

const CallEnterInfoPanel: React.FC<CallEnterInfoPanelProps> = ({ userSocketFeedback, join }) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);

    return (
        <Box className={classes.root}>
            <Paper variant="outlined">
                <Box
                    p={2}
                    className={classes.textBox}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box p={2}>
                        <p className={classes.title}>Velkommen til din videosamtale i headspace</p>
                        <p className={classes.text}>
                            Find et sted, hvor du kan sidde rart og uforstyrret. Brug evt. høretelefoner, da det kan
                            forbedre lyden. Overvej, om der er noget i baggrunden, som er privat, og som du ikke ønsker,
                            at rådgiverne skal se.
                        </p>

                        <Box display="flex" alignItems="center" mb={2} className={classes.checkBox}>
                            <Checkbox checked={checked} onChange={() => setChecked(!checked)} color="primary" />
                            <span>
                                Jeg accepterer{' '}
                                <Link href="https://www.headspace.dk/om-headspace/privatlivspolitik" target="_blank">
                                    Privatlivspolitikken
                                </Link>
                            </span>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={userSocketFeedback.loading || !checked}
                            onClick={join}
                            style={{ padding: '12px 18px' }}
                        >
                            <Box
                                display={userSocketFeedback.loading ? 'flex' : 'none'}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                height="100%"
                                position="absolute"
                                width="100%"
                                zIndex={3}
                            >
                                <CircularProgress size={30} style={{ color: 'white' }} />
                            </Box>

                            <span style={{ paddingRight: 8 }}>Deltag</span>
                            <Video style={{ marginBottom: 2 }} size={20} />
                        </Button>

                        <Divider style={{ marginBottom: 8, marginTop: 16 }} />

                        <p className={classes.disclaimerText}>
                            Du må ikke optage samtalen. headspace optager heller ikke samtalen. Videosamtalen er et
                            fortroligt rum, som ikke bruges til andet end den konkrete samtale.
                        </p>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default CallEnterInfoPanel;
