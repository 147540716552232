import React from 'react';
import { Box, Fab, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { theme_vars } from '../../constants/theme';
import { Mic, MicOff, Phone, Video, VideoOff } from 'react-feather';

interface StreamActionsStyleProps {
    animate?: boolean;
    bottom: number;
    active: boolean;
}

const useStyles = makeStyles<Theme, StreamActionsStyleProps>((theme) => ({
    root: {
        position: 'absolute',
        zIndex: 3,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            bottom: (props) => (props.active ? theme.spacing(props.bottom) : -100),
        },
        bottom: (props) => theme.spacing(props.bottom / 2),
        left: 0,
        right: 0,
        transition: (props) => (props.animate ? 'all 0.4s ease' : 'unset'),
        pointerEvents: 'none',
    },
    iconButton: {
        margin: theme.spacing(1),
        pointerEvents: 'auto',
    },
}));

interface StreamActionsProps {
    active?: boolean;
    bottom: number;
    animate?: boolean;
    audioEnabled: boolean;
    videoEnabled: boolean;
    onUpdateAudio: () => void;
    onUpdateVideo: () => void;
    onEndCall?: () => void;
}

const StreamActions: React.FC<StreamActionsProps> = ({
    bottom,
    audioEnabled,
    videoEnabled,
    onUpdateAudio,
    onUpdateVideo,
    onEndCall,
    animate = true,
    active = true,
}) => {
    const classes = useStyles({ active, animate, bottom });

    const handleEndCall = () => {
        onEndCall && onEndCall();
    };

    return (
        <Box className={classes.root} display="flex" justifyContent="center" alignItems="center" width="100%">
            <Fab
                className={classes.iconButton}
                style={{ background: audioEnabled ? theme_vars.BLACK_OPAQUE_BG : theme_vars.RED_OPAQUE_BG }}
                onClick={() => onUpdateAudio()}
            >
                {audioEnabled && <Mic color="white" />}
                {!audioEnabled && <MicOff color="white" />}
            </Fab>

            {onEndCall && (
                <Fab
                    className={classes.iconButton}
                    style={{ background: theme_vars.RED_OPAQUE_BG }}
                    onClick={handleEndCall}
                >
                    <Phone color="white" />
                </Fab>
            )}

            <Fab
                className={classes.iconButton}
                style={{ background: videoEnabled ? theme_vars.BLACK_OPAQUE_BG : theme_vars.RED_OPAQUE_BG }}
                onClick={() => onUpdateVideo()}
            >
                {videoEnabled && <Video color="white" />}
                {!videoEnabled && <VideoOff color="white" />}
            </Fab>
        </Box>
    );
};

export default StreamActions;
