import { detect } from 'detect-browser';

const browser = detect();

const browserSupportWebRTCMap: { [p: string]: boolean } = {
    crios: false,
    'edge-ios': false,
    'ios-webview': false,
    facebook: false,
    instagram: false,
    'chromium-webview': false,
};

export const isWebRTCCompatible = (): boolean => {
    const isWebRTCSupported = navigator.getUserMedia;
    if (browser?.name === undefined) return false;
    return browserSupportWebRTCMap[browser.name] ?? !!isWebRTCSupported;
};
