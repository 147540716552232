import StreamPeer from './StreamPeer';
import { Box, Theme } from '@material-ui/core';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { RTCPeer } from '../../models/RTCPeer';
import { RTCPeerState } from '../../models/RTCPeerState';
import { VideoScreenStyleProps } from './StreamScreen';

interface Size {
    width: React.CSSProperties['width'];
    height: React.CSSProperties['height'];
}

const useStyles = makeStyles<Theme, Size>(() => ({
    root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        width: ({ width }) => width,
        height: ({ height }) => height,
        // '& video': {
        //     height: '100%',
        //     width: '177.77777778vh', // 100 * 16 / 9,
        //     minWidth: '100%',
        //     minHeight: '56.25vw', // 100 * 9 / 16
        //     position: 'absolute',
        //     left: '50%', // % of surrounding element
        //     top: '50%',
        //     transform: 'translate(-50%, -50%)', // % of current element
        // },
    },
}));

type StreamPeerBoxProps = Size & {
    peer: RTCPeer;
    state: RTCPeerState | undefined;
};

const StreamPeerBox: React.FC<StreamPeerBoxProps> = ({ width, height, peer, state }) => {
    const classes = useStyles({ width, height });

    const props: VideoScreenStyleProps = {
        local: false,
        zIndex: 1,
        iconSize: 'large',
        height: '100%',
        width: 'unset',
    };

    // TODO: Giver error message / feedback if so...
    if (!state) return null;

    return (
        <Box className={classes.root}>
            <StreamPeer videoScreenStyleProps={props} rtcPeerState={state} rtcPeer={peer} />
        </Box>
    );
};

export default StreamPeerBox;
