import tillad_mikrofon_og_kamera_3 from './images/tillad_mikrofon_og_kamera_3.png';
import tillad_mikrofon_og_kamera_4 from './images/tillad_mikrofon_og_kamera_4.png';

export default `
## Mit kamera virker ikke efter jeg har tilladt det

Du bedes først kontrollere at du allerede har tilladt at siden benytter sig af din pc's mikrofon og kamera. Dette kan gøres ved at holde musen over kamera ikonet ved siden af browserens adressefelt. Du burde her kunne se en besked svarende til "Denne side har adgang til dit kamera og din mikrofon".

![Klik på "PRØV IGEN"](${tillad_mikrofon_og_kamera_4})

Fejlen kan skylde at du prøver at tilgå videosamtalen gennem fjernskrivebordet. Hvis dette er tilfældet, bedes du logge af fjernskrivebordet, og prøve at tilgå siden igen.

Har du ikke fjernskrivebordet åbent, kan fejlen skyldes at en anden process optager dit kamera. Denne anden process kan være alt fra kamera programmet på din pc, eller et andet video program såsom Skype. Du bedes lukke denne process og derefter klikke på "PRØV IGEN".

![Klik på "PRØV IGEN"](${tillad_mikrofon_og_kamera_3})
`;
