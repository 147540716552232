import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { AppState, initialApplicationState } from './store';
import { Redirect, Route, Switch } from 'react-router';
import Routes from './constants/routes';
import CallPage from './pages/Call/CallPage';
import SupportPage from './pages/Support/SupportPage';
import LandingPage from './pages/Landing/LandingPage';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { defaultThemeConfig } from './constants/theme';

const persistedState: AppState = {
    ...initialApplicationState,
};

const history = createBrowserHistory();
const store = configureStore(history, persistedState);

const App = () => {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ThemeProvider theme={createMuiTheme(defaultThemeConfig)}>
                    <Switch>
                        <Route path={Routes.call} component={CallPage} />
                        <Route path={Routes.help} component={SupportPage} />
                        <Route exact path={Routes.landing} component={LandingPage} />
                        <Redirect path={Routes.landing} to={Routes.landing} />
                    </Switch>
                </ThemeProvider>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;
