import React, { useEffect, useState } from 'react';
import { RTCPeerState } from '../../models/RTCPeerState';
import StreamScreen, { VideoScreenStyleProps } from './StreamScreen';
import { RTCPeer } from '../../models/RTCPeer';

interface StreamPeerProps {
    rtcPeerState?: RTCPeerState;
    videoScreenStyleProps: VideoScreenStyleProps;
    rtcPeer: RTCPeer;
    onSpeakingPersonChange?: (peer: RTCPeer) => void;
}

const StreamPeer: React.FC<StreamPeerProps> = ({ rtcPeerState, videoScreenStyleProps, rtcPeer }) => {
    const [stream, setStream] = useState<MediaStream>();

    useEffect(() => {
        setStream(rtcPeer.stream);
    }, [rtcPeer.stream]);

    return (
        <StreamScreen
            videoScreenStyleProps={videoScreenStyleProps}
            stream={stream}
            videoEnabled={rtcPeerState?.videoEnabled ?? false}
            audioEnabled={rtcPeerState?.audioEnabled ?? false}
        />
    );
};

export default StreamPeer;
