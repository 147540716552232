import { config } from '../constants/config';
import { Location } from 'history';

export const getRedirectLink = async (location: Location<unknown>, id: string) => {
    const qParam = new URLSearchParams(location.search).get('type');

    const res = await fetch(config.HOST + '/api/v1/calls/' + id + '/redirects');
    if (!res.ok) return;

    const json = await res.json();
    if (!json) return;

    if (!qParam) return json['default'];
    const linkKey = json[qParam];

    if (!linkKey) return json['default'];
    return linkKey;
};

const postLog = async (path: 'join' | 'end', id: string, localId: string | undefined, numberOfPeers: number) => {
    const { platform, product, userAgent, vendor } = navigator;
    const logRequest = {
        id,
        localId,
        numberOfPeers,
        platform,
        product,
        vendor,
        userAgent,
    };

    return await fetch(config.HOST + '/api/v1/log/' + path, {
        method: 'POST',
        headers: {
            'Content-Type': 'text/json',
        },
        body: JSON.stringify(logRequest),
    });
};

export const postJoinLog = async (id: string, localId: string | undefined, numberOfPeers: number) => {
    await postLog('join', id, localId, numberOfPeers);
};

export const postEndLog = async (id: string, localId: string | undefined, numberOfPeers: number) => {
    await postLog('end', id, localId, numberOfPeers);
};
