import React from 'react';
import CenterLayout from '../../components/CenterLayout';
import { Box } from '@material-ui/core';
import { UserMediaFeedback, UserSocketFeedback } from '../../models/Feedback';
import CallEnterInfoPanel from './CallEnterInfoPanel';
import CallEnterPageHeader from './CallEnterPageHeader';
import StreamCardWrapper from '../../components/VideoStream/StreamCardWrapper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    contentFlexWrapper: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        flexWrap: 'wrap-reverse',
    },
}));

interface CallEnterPageProps {
    localId?: string;
    stream?: MediaStream;
    join: () => void;
    userMediaFeedback: UserMediaFeedback;
    userSocketFeedback: UserSocketFeedback;
    localAudioEnabled: boolean;
    localVideoEnabled: boolean;
    onUpdateVideo: () => void;
    onUpdateAudio: () => void;
}

const CallEnterPage: React.FC<CallEnterPageProps> = (props) => {
    const {
        localId,
        stream,
        join,
        userMediaFeedback,
        userSocketFeedback,
        localAudioEnabled,
        localVideoEnabled,
        onUpdateVideo,
        onUpdateAudio,
    } = props;
    const classes = useStyles();
    return (
        <CenterLayout maxWidth={false} phoneScroll={true}>
            <Box height="100%" width="100%" display="flex" flexDirection="column">
                <CallEnterPageHeader />
                <Box className={classes.contentFlexWrapper}>
                    <StreamCardWrapper
                        id={localId}
                        selectedId={''}
                        autoSelectedId={''}
                        local={true}
                        userMediaFeedback={userMediaFeedback}
                        stream={stream}
                        videoEnabled={localVideoEnabled}
                        audioEnabled={localAudioEnabled}
                        onUpdateVideo={onUpdateVideo}
                        onUpdateAudio={onUpdateAudio}
                    />
                    <CallEnterInfoPanel userSocketFeedback={userSocketFeedback} join={join} />
                </Box>
            </Box>
        </CenterLayout>
    );
};

export default CallEnterPage;
