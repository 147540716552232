import React from 'react';
import { Box, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import getAspectSize from '../lib/getAspectSize';

interface AspectRatioProps {
    x: number;
    y: number;
}

const useStyles = makeStyles<Theme, AspectRatioProps>(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        overflow: 'hidden',
        transition: 'all 500ms ease',
        width: ({ x }) => x,
        height: ({ y }) => y,
    },
}));

const AspectRatioBox: React.FC<AspectRatioProps> = ({ x, y, children }) => {
    const { nx, ny } = getAspectSize(x, y);
    const classes = useStyles({ x: nx, y: ny });

    return <Box className={classes.root}>{children}</Box>;
};

export default AspectRatioBox;
