import { applyMiddleware, combineReducers, compose, createStore, StoreEnhancer } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { AppState, reducers } from './';

export default function configureStore(history: History, initialState?: AppState) {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    const enhancers: StoreEnhancer[] = [];

    return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
