import { callReducer, CallState, initialCallState } from './CallState';
import { RouterState } from 'connected-react-router';

export interface AppState {
    call: CallState;
    router?: RouterState;
}

export const initialApplicationState: AppState = {
    call: initialCallState,
};

export const reducers = {
    call: callReducer,
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => AppState): void;
}
