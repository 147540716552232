import React, { useCallback, useEffect, useState } from 'react';
import { theme_vars } from '../../constants/theme';
import { useDispatch, useSelector } from 'react-redux';
import { callActions } from '../../store/CallState';
import { AppState } from '../../store';
import { useLocation, useParams } from 'react-router';
import { isWebRTCCompatible } from '../../lib/RTC';
import {
    initialUserSocketFeedback,
    UserMediaFeedback,
    UserMediaFeedbackCode,
    userMediaFeedbackMap,
    UserSocketFeedback,
} from '../../models/Feedback';
import { getRedirectLink, postEndLog, postJoinLog } from '../../lib/appClient';
import { initialStreamConstraints } from '../../constants/config';
import { StreamType } from '../../models/Stream';
import { handleUpdateStream } from '../../lib/video';
import CallEnterPage from './CallEnterPage';
import CallInPage from './CallInPage';
import CallLeavePage from './CallLeavePage';

const ENTER = 0;
const IN = 1;
const LEAVE = 2;

const CallPage: React.FC = () => {
    const [page, setPage] = useState<number>(0);
    const [redirect, setRedirect] = useState();
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation();

    const {
        localStream,
        localId,
        localDeviceId,
        localAudioEnabled,
        localVideoEnabled,
        rtcPeers,
        rtcPeerStates,
        autoSelectedId,
        selectedId,
        messages,
    } = useSelector((state: AppState) => state.call);

    const [actionsActive, setActionsActive] = useState(true);
    const [userMediaFeedback, setUserMediaFeedback] = useState<UserMediaFeedback>({
        ...userMediaFeedbackMap[UserMediaFeedbackCode.initial],
    });
    const [userSocketFeedback, setUserSocketFeedback] = useState<UserSocketFeedback>({ ...initialUserSocketFeedback });

    const handleStartCall = useCallback(async () => {
        const compatible = isWebRTCCompatible();
        if (!compatible) {
            setUserMediaFeedback(userMediaFeedbackMap[UserMediaFeedbackCode.notWebRTCCompatible]);
            return;
        }

        const updatedStream = await handleUpdateStream(new MediaStream(), initialStreamConstraints);
        await dispatch(callActions.setVideoStream(updatedStream.stream));
        if (!updatedStream.success) {
            setUserMediaFeedback(userMediaFeedbackMap[UserMediaFeedbackCode.permissions]);
            return;
        }

        await setUserMediaFeedback({ loading: false, success: true });
        await dispatch(callActions.connect(id));
        await setUserSocketFeedback({ loading: false, success: true });
    }, [dispatch, id]);

    const handleSetRedirect = useCallback(async () => {
        const redirect = await getRedirectLink(location, id);
        setRedirect(redirect);
    }, [location, id]);

    const handleJoin = async () => {
        await setPage(IN);
        await dispatch(callActions.join());
        await setUserMediaFeedback({ loading: false, success: true });
        await postJoinLog(id, localId, rtcPeers.length);
    };

    const handleUpdateVideo = () => {
        dispatch(callActions.updateStream(StreamType.video));
        setUserMediaFeedback(
            userMediaFeedbackMap[localVideoEnabled ? UserMediaFeedbackCode.cameraOff : UserMediaFeedbackCode.empty],
        );
    };

    const handleUpdateAudio = () => {
        dispatch(callActions.updateStream(StreamType.audio));
    };

    const handleClickHangup = async () => {
        await setPage(LEAVE);
        await dispatch(callActions.disconnect(id));
        await postEndLog(id, localId, rtcPeers.length);
    };

    const handleBack = () => {
        setPage(ENTER);
        handleStartCall();
    };

    useEffect(() => {
        let timeOut: NodeJS.Timeout;
        const handleMouseMove = () => {
            clearTimeout(timeOut);
            setActionsActive(true);
            timeOut = setTimeout(() => {
                setActionsActive(false);
            }, theme_vars.HIDE_PANEL_TIMEOUT);
        };
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            clearTimeout(timeOut);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        handleStartCall();
    }, [handleStartCall]);

    useEffect(() => {
        handleSetRedirect();
    }, [handleSetRedirect]);

    if (page === ENTER)
        return (
            <CallEnterPage
                localId={localId}
                stream={localStream}
                join={handleJoin}
                userMediaFeedback={userMediaFeedback}
                userSocketFeedback={userSocketFeedback}
                localAudioEnabled={localAudioEnabled}
                localVideoEnabled={localVideoEnabled}
                onUpdateAudio={handleUpdateAudio}
                onUpdateVideo={handleUpdateVideo}
            />
        );

    if (page === IN)
        return (
            <CallInPage
                localId={localId}
                localDeviceId={localDeviceId}
                selectedId={selectedId}
                autoSelectedId={autoSelectedId}
                localAudioEnabled={localAudioEnabled}
                localVideoEnabled={localVideoEnabled}
                actionsActive={actionsActive}
                localStream={localStream}
                rtcPeers={rtcPeers}
                peers={rtcPeerStates}
                messages={messages}
                userMediaFeedback={userMediaFeedback}
                userSocketFeedback={userSocketFeedback}
                onEndCall={handleClickHangup}
                onUpdateAudio={handleUpdateAudio}
                onUpdateVideo={handleUpdateVideo}
            />
        );

    return <CallLeavePage onBack={handleBack} redirect={redirect} />;
};

export default CallPage;
